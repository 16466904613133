import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {ApolloClient, ApolloProvider, HttpLink, from} from '@apollo/client';
import {onError as onApolloError} from '@apollo/client/link/error';
import {noop} from 'lodash';

import {GET_CURRENT_USER, fetchWithRateLimitHandler} from '@renofi/api';
import {useNotifications} from '@renofi/components';
import {
  areAuthTokensExpired,
  badQueryErrorLink,
  hasStoredJwt,
  isJwtStale,
} from '@renofi/utils';

import LoadingSpinner from './LoadingSpinner';
import useWatchJwtCookie from './useWatchAuthCookie';
import {
  authLink,
  errorHandler,
  graphQLErrorLink,
  watchQueryLink,
  redirectToLoginWithRedirect,
  authenticateByLoginToken,
  initAuthTokenRefresh,
  setCookieByDomain,
} from './utils';

const AuthApolloClient = ({
  analyticsPrefix,
  cache,
  children,
  onAuthSuccess: onSuccess = noop,
  onAuthError: onError = redirectToLoginWithRedirect,
  redirectTo = '/',
  tokenLoginPath = '/token/:tokenId',
}) => {
  const history = useHistory();
  const {search} = useLocation();
  const addNotification = useNotifications();

  const tokenLogin = useRouteMatch(tokenLoginPath);
  const [ready, setReady] = useState(false);

  const onAuthError = (params = {}) => {
    onError({history, ...params});
  };

  const onAuthSuccess = () => {
    onSuccess({history});
  };

  /**
   * ApolloClient configuration...
   */
  const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
  const httpLink = new HttpLink({
    uri: apiUrl,
    fetch: fetchWithRateLimitHandler,
  });

  const errorLink = onApolloError(errorHandler(onAuthError));
  const client = new ApolloClient({
    cache,
    name: process.env.REACT_APP_SERVICE_NAME,
    version: process.env.REACT_APP_COMMIT_REF,
    link: from([
      errorLink,
      graphQLErrorLink,
      badQueryErrorLink,
      authLink,
      watchQueryLink,
      httpLink,
    ]),
  });

  const fetchCurrentUser = async () => {
    try {
      await client.query({query: GET_CURRENT_USER});
      onAuthSuccess();
    } catch (err) {
      onAuthError();
    }
  };

  const forwardQueryString = async () => {
    const searchParams = new URLSearchParams(search);
    const path = searchParams.get('redirectTo') || redirectTo;

    // Ensure auth value are definitely removed...
    searchParams.delete('jwt');
    searchParams.delete('refreshToken');
    searchParams.delete('redirectTo');

    history.push(`${path}?${searchParams.toString()}`);
  };

  useWatchJwtCookie({onAuthError});

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const jwt = searchParams.get('jwt');
    const refreshToken = searchParams.get('refreshToken');

    (async () => {
      const isTokenLogin = Boolean(tokenLogin);
      const token = tokenLogin?.params?.tokenId;

      switch (true) {
        // If JWT passed along QueryString ...
        case Boolean(jwt && refreshToken):
          setCookieByDomain('jwt', jwt);
          setCookieByDomain('refreshToken', refreshToken);
          await fetchCurrentUser(client);
          forwardQueryString();

          return setReady(true);
        // If logging in through loginToken
        case Boolean(isTokenLogin && token):
          await authenticateByLoginToken({
            analyticsPrefix,
            client,
            onAuthError,
            onAuthSuccess,
            token,
          });
          forwardQueryString();
          return setReady(true);

        // Check if either token's exp value is expired (in the past)
        case areAuthTokensExpired(addNotification):
          setReady(true);
          return onAuthError();

        case isJwtStale():
          // Mark it immediately as ready so we can kickstart refreshToken
          return setReady(true);

        // If JWT present & it's NOT stale...
        case hasStoredJwt() && !isJwtStale():
          await fetchCurrentUser(client);
          return setReady(true);
        default:
          setReady(true);
          return onAuthError();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!ready) {
        return false;
      }

      const timeoutId = await initAuthTokenRefresh(client);
      const user = await client.readQuery({query: GET_CURRENT_USER});
      if (Boolean(timeoutId && !user)) {
        await fetchCurrentUser(client);
      }

      return () => clearTimeout(timeoutId);
    })();
  }, [ready]);

  return (
    <ApolloProvider client={client}>
      {ready ? children : <LoadingSpinner />}
    </ApolloProvider>
  );
};

AuthApolloClient.propTypes = {
  analyticsPrefix: PropTypes.string.isRequired,
  onAuthSuccess: PropTypes.func,
  onAuthError: PropTypes.func,
  tokenLoginPath: PropTypes.string,
};

export default AuthApolloClient;
